import React from "react";
import { useQuery, gql } from '@apollo/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Post from "./Post";

const DATA = gql`
    query MyQuery {
        postsConnection {
            edges {
                node {
                    id
                    createdAt
                    slug
                    title
                    excerpt
                    coverImage {
                        url
                    }
                    categories {
                        name
                        slug
                    }
                    content {
                        html
                        json
                    }
                    author {
                        bio
                        name
                        id
                        image {
                            url
                        }
                    }
                    photoCollection {
                      id
                      fileName
                      url
                    }
                    featuredPost
                }
            }
        }
    }
`;

function BlogPage() {
    const { loading, error, data } = useQuery(DATA);
    console.log(data);

    if (loading) return 'Loading...!';
    if (error) return `Error. ${error.message}`;

    return (
        <Router>
            <Routes>
                {data.postsConnection.edges.map((track) => (
                    <Route
                        key={track.node.id}
                        path={`/blog/post/:${track.node.id}`}
                        element={<Post />}
                    />
                ))}
            </Routes>
        </Router>
    );
}

export default BlogPage;