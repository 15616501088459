import React, { useEffect, useState } from 'react';
import axios from 'axios';

const PodcastHome = () => {
    const [podcasts, setPodcasts] = useState([]);

    useEffect(() => {
        const fetchPodcasts = async () => {
            try {
                const response = await axios.get('http://localhost:5000/api/podcast');
                setPodcasts(response.data);
            } catch (error) {
                console.error('Error in fetching podcasts:', error);
            }
        };

        fetchPodcasts();
    }, []);

    return (
        <div>
            <h1>Podcast Home Page</h1>
            {podcasts.length === 0 ? (
                <p>No podcasts available.</p>
            ) : (
                podcasts.map(podcast => (
                    <div key={podcast._id}>
                        <h2>{podcast.title}</h2>
                        <p>{podcast.description}</p>
                        <audio controls>
                            <source src={podcast.audioFile} type="audio/mpeg" />
                            Your browser does not support the audio element.
                        </audio>
                        <p>Hosts: {podcast.hosts.join(', ')}</p>
                        <img src={podcast.thumbnail} alt={podcast.title} />
                    </div>
                ))
            )}
        </div>
    );
};

export default PodcastHome;