import React, { useEffect, useState } from "react";
import "./Post.css";
import { useQuery, gql } from '@apollo/client';
import { RichText } from "@graphcms/rich-text-react-renderer";
import { useParams } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import DashedLines from "./ImageGalleryDots";

import SEO from "./SEO";



const DATA = gql`
    query MyQuery {
        postsConnection {
            edges {
                node {
                    id
                    createdAt
                    slug
                    title
                    excerpt
                    coverImage {
                        url
                    }
                    categories {
                        name
                        slug
                    }
                    content {
                        html
                        json
                    }
                    author {
                        bio
                        name
                        id
                        image {
                            url
                        }
                    }
                    photoCollection {
                      id
                      fileName
                      imageTitle
                      url
                    }
                    featuredPost
                    postNumber
                }
            }
        }
    }
`;

const getOrdinalSuffix = (number) => {
    if (!Number.isInteger(number)) return "";
    
    const remainder = number % 100;
    
    if (remainder >= 11 && remainder <= 13) return `${number}th`; // Special case for 11, 12, 13
    
    switch (number % 10) {
        case 1:
            return `st`;
        case 2:
            return `nd`;
        case 3:
            return `rd`;
        default:
            return `th`;
    }
};


export default function Post(){

    let get_id = useParams();
    
    let [value, setValue] = useState(0);

    
    const { loading, error, data } = useQuery(DATA);

    get_id = Object.values(get_id).join();
    console.log("Post page: ", data);

    useEffect(() => {
        // Initialize ads
        const ads = document.getElementsByClassName('adsbygoogle');
        for (let i = 0; i < ads.length; i++) {
            try {
                (window.adsbygoogle = window.adsbygoogle || []).push({});
            } catch (e) {
                console.error("AdSense error: ", e);
            }
        }
    }, []);
    
    if(loading) return <div class="loader"></div>;
    
    if(error) return `Error. ${error.message}`;

    
    return data.postsConnection.edges.filter(data => data.node.id === get_id).map(( track ) => (
        <>
            <div key={track.node.id} className="post-area" id="post-area">
                <img src={`${track.node.coverImage.url}`} className="cover-image" />
                <p className="title">{track.node.title}</p>
                <p className="excerpt">{track.node.excerpt}</p>

                <SEO 
                    title={track.node.title + " | Hari Vishnu"}
                    description={track.node.excerpt}
                />

                <div className="other-details">
                    <p className="date-issue">{(new Date(track.node.createdAt)).toLocaleDateString()}</p>
                    <img className="author-img" src={track.node.author.image.url} alt="author-image"/>
                    <p className="author">{track.node.author.name}</p>
                </div>
                
                <hr className="hr-line"/>

                <RichText
                    key={track.node.id} 
                    className="rich-text-comp"
                    content={track.node.content.json}
                    
                    renderers={{
                        p: ({ children }) => <p className="rt rt-para">{children}</p>,
                        
                        h1: ({ children }) => <h1 className="rt rt-h1">{children}</h1>,
                        h2: ({ children }) => <h2 className="rt rt-h2">{children}</h2>,
                        h3: ({ children }) => <h3 className="rt rt-h3">{children}</h3>,
                        h4: ({ children }) => <h4 className="rt rt-h4">{children}</h4>,
                        h5: ({ children }) => <h5 className="rt rt-h5">{children}</h5>,
                        h6: ({ children }) => <h6 className="rt rt-h6">{children}</h6>,
                        
                        ul: ({ children }) => <ul className="rt rt-ul">{children}</ul>,
                        ol: ({ children }) => <ol className="rt rt-ol">{children}</ol>,
                        li: ({ children }) => <li className="rt rt-li">{children}</li>,
                        
                        img: ({ src }) => 
                            <div className="rt rt-img" id="img01">
                                <img className="" src={src} alt="post-image" />
                            </div>,
                        
                        bold: ({ children }) => <strong className="rt rt-bold">{children}</strong>,
                        a: ({ href, children, openInNewTab}) => 
                            <a className="rt rt-link" href={href} target={openInNewTab ? '_blank' : '_self'}>{children}</a>
                    }}
                /> 

                <div className="image-post-area" style={{display: track.node.photoCollection.length ? '' : 'none'}}>
                    <div className="image-details">
                        <LazyLoadImage
                            className="image-post-view" 
                            src={track.node.photoCollection.length ? track.node.photoCollection[value].url : ""} 
                            alt={`collection-image-${value+1}`}
                            effect="blur"
                        />
                        {/* <img 
                            className="image-post-view" 
                            src={track.node.photoCollection.length ? track.node.photoCollection[value].url : ""} 
                            alt={`collection-image-${value+1}`}
                        /> */}

                        <DashedLines totalDashes={track.node.photoCollection.length} currentDashIndex={value} />
                        
                        <p 
                            className="image-title"
                            style={{display: (value+1) ? '' : 'none'}}>
                                <strong>
                                    {value+1 ? value+1 : ""}<sup>{getOrdinalSuffix(value+1)}</sup> image
                                </strong>: {(track.node.photoCollection.length) ? track.node.photoCollection[value].imageTitle : ""}
                        </p>
                    </div>
                    
                    {/* <div className="image=dots">
                        <ImageGalleryDots />
                    </div> */}

                    <div className="image-post-controls">
                        <button 
                            className="prev-post-btn"
                            onClick={() => setValue(a => a-1)}
                            disabled={value===0 ? true : ""}
                            ><i class="fi fi-rr-caret-left"></i>
                        </button>
                        
                        <p className="image-post-number">{value+1} / {track.node.photoCollection.length}</p>

                        <button 
                            className="next-post-btn"
                            onClick={() => setValue(a => a+1)}
                            disabled={value===track.node.photoCollection.length-1 ? true : ""}
                            ><i class="fi fi-rr-caret-right"></i>
                        </button>
                    </div>
                </div>             
                    
                {/* Google AdSense Ad */}
                <div className="adsense-ad">
                    <ins className="adsbygoogle"
                        style={{ display: "block", textAlign: "center" }}
                        data-ad-layout="in-article"
                        data-ad-format="fluid"
                        data-ad-client="ca-pub-7392648336103424" // Replace with your AdSense client ID
                        data-ad-slot="4633725050"></ins>
                    <script>
                        (adsbygoogle = window.adsbygoogle || []).push({});
                    </script>
                </div>

            </div>
        </>
    ));
};  