import React from "react";
import './Header.css';
import './index.css';
import { BrowserRouter as Router,  NavLink } from 'react-router-dom';


function openNav() {
    var wid = window.screen.availWidth ;
    if(wid<=480 && wid>=320){
        document.getElementById("mySidenav").style.width = "100%";
        document.getElementById("mySidenav").style.zIndex = "2";
    }
    else{
        document.getElementById("mySidenav").style.width = "20%";
    }
    document.getElementById("ham_btn").style.visibility = "hidden";
}

function closeNav() {
    document.getElementById("mySidenav").style.width = "0";
    document.getElementsByTagName("BODY")[0].style.marginLeft = "0";
    document.getElementById("ham_btn").style.visibility = "visible";    
}


function myFunction() {
    var x = document.getElementById("lists");
    var arrow = document.getElementById("arrow");
    if (x.style.display === "none") {
        x.style.display = "block";
        arrow.style.transform = 'rotatex(180deg)';
    } else {
        x.style.display = "none";
        arrow.style.transform = 'rotatex(0deg)';
    }
}

function changeMode() {
    document.body.classList.toggle("dark-mode");
}



function Navigation(){
    return(
        <div className="main_area" id="main">
            <div className="header">
                <span onClick={openNav} className="option">
                    <div className="hamburger_btn" id="ham_btn">
                        <div className="line-1 line"></div>
                        <div className="line-2 line"></div>
                        <div className="line-3 line"></div>
                    </div>
                </span>

                <img className="logo_img" src="/images/home_images/Logo_Transparent.png" alt="" style={{width: "35px"}} />
            </div>
        </div>
    )
}

function SideNav(){
    return(      
        <>  
        <div id="mySidenav" className="sidenav">
            <div className="closebtn" onClick={closeNav}>
                <p>Esc</p>
                <i id="close_btn" className="fi fi-rr-cross-small"></i>
            </div>

            <hr />


            <NavLink className="btn" to="/">
                <i class="fi fi-sr-house-chimney-window"></i>
                <p>Home</p>
            </NavLink>


            <NavLink className="btn blog-btn" to="/blog">
                <i class="fi fi-br-blog-text"></i>
                <p>Blog</p>
            </NavLink>

            {/* <NavLink className="btn podcast-btn" to="/podcast">
                <i class="fi fi-sc-microphone-alt"></i>
                <p>Podcast</p>
            </NavLink> */}

            <NavLink className="btn contact-btn" to="/Contact">
                <i class="fi fi-sr-clipboard-user"></i>
                <p>Contact</p>
            </NavLink>


            {/* <NavLink className="btn store-btn" to="/store">
                <i class="fi fi-ss-store-alt"></i>
                <p>Store</p>
            </NavLink> */}
                        

            <div className="settings-btn">
                <div className="btn settings-btn-heading" onClick={myFunction}>
                    <i class="fi fi-sr-settings"></i>
                    <p>Settings</p>
                    <i className="fi fi-rr-caret-down arrow" id="arrow"></i>
                </div>
                <div className="orderlist" id="lists" style={{display: "none"}}>
                    <div className="btn theme-btn">
                        <i class="fi fi-sr-moon-stars"></i>
                        <p>Dark Theme</p>
                        <label className="switch">
                            <input type="checkbox" id="toggle" onClick={changeMode} />
                            <span className="slider round"></span>
                        </label>
                    </div>
                    <div className="btn language-btn">
                        <i class="fi fi-sr-globe"></i>
                        <p>Language: English</p>
                    </div>
                </div>
            </div>

        </div>
        <script src=".src/Script.js" type="text/javascirpt" />
        </>
    )
}




export default function Header(){
    return(
        <>
            <Navigation />
            <SideNav />
        </>
    )
}